import React from 'react'

export default function BodyInf() {
  return (
    <div>    
       <li className="bodyinf-container" id="Rsocial" >
       <div className="bodyinf-left">
       Réseau social
       </div>
       <div className="bodyinf-right">
          <div className="parag">
          Vous êtes un utilisateur accro aux platesformes sociales, sur
              SoWaySo vous recevez 30 centimes d’euro sur chaque texto, photo,
              vidéo, et même chaque publicité consultée sur notre plate-forme,
              et vos gains mensuels peuvent aller jusqu’à 180€ et des bons
              d’achat Interne jusqu’à 630 €
          </div>
        </div>
        </li>

        <li className="bodyinf-container" id="solidaire" >

        <div className="bodyinf-left">
        Univers solidaire
        </div>
        <div className="bodyinf-right">
          <div className="parag">
          Vous êtes vidéo blogueur, producteur médiatique, artiste, acteur,
          chanteur, et même entreprise de diffusion médiatique et presse.
          support de diffusion Web digitale pour
          pouvoir partager vos productions et recevoir des gains jusqu’à 10 fois
          plus que toutes autres plates-formes et surtout pour fidéliser vos
          fans par des cadeaux de prestige. Jusqu’à 10 000 € pour un million de
          vu
          </div>
        </div>
        </li>

        <li className="bodyinf-container"  id="plateformes" >

        <div className="bodyinf-left">
           Gagner de l'argent 
        </div>
        <div className="bodyinf-right">
          <div className="parag">
          Vous aimez partager votre journée et activités avec vos amis par des
          textos et des photos quotidiennement. Larecevoir des primes équivalents à 05 € sur chaque 100 vu sur chaque
          publication personnelle.
          </div>
        </div>
        </li>

        <li className="bodyinf-container"  id="TanitCoin" >
        <div className="bodyinf-left">
           Valorisation des activités
        </div>
        <div className="bodyinf-right">
          <div className="parag">
          SoWaySo offre à chaque annonceur SoCase et/ou SoMark l’opportunité de
          diffuser leur produit et service solidairement avec leurs clientèles
          et la possibilité de recevoir des certificats de garantie de chiffre
          d’affaire sur notre plate-forme. Jusqu’à 1 000 0000 € pour 15 millions
          de fans
          </div>
        </div>
        </li>
       
    </div>
  )
}
