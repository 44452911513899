import React from 'react'

export default function SideBarinf() {
  return (
    <div className="sidebarinf-container">
        <ul>
            <li>
                <a href='#Rsocial'>C’est plus qu’un réseau social, c’est plus qu’un crypto monnaie d’investissement</a>
            </li>
            <li>
                <a href='#solidaire'>C’est notre univers solidaire pour un monde meilleur</a>
            </li>
            <li>
                <a href='#plateformes'>Sur les autres plateformes je passe beaucoup de temps sans aucun gain</a>
            </li>
            <li>
                <a href='#TanitCoin'>SoWaySo et TanitCoin valorise tous mes activités avec des primes de qualité</a>
            </li>
        </ul>
    </div>
  )
}
